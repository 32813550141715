import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp(filter: { original: { src: { regex: "/project.*/" } } }) {
        edges {
          node {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  function findImage(projectName) {
    const result = data.allImageSharp.edges.find(
      ({ node }) => node.fluid.src.indexOf(projectName) > -1 && node.fluid
    )

    return result.node.fluid
  }

  return (
    <Layout>
      <SEO title="Projects" description="Ricardo Macario's Projects" />
      <div className="max-w-2xl mx-auto">
        <h1>Projects</h1>
        <div>
          <ProjectCard title="Appdelante" image={findImage("appdelante")}>
            <p>
              Appdelante is a Youtube channel, website, blog, courses and email
              newsletter where my brother{" "}
              <a href="https://twitter.com/DanMacarioB">Daniel</a> and I teach
              Software development in Spanish.
            </p>
            <p>
              The Youtube videos have more than 250k views and our courses have
              been taken by hundreds of students.
            </p>
            <p className="mb-0">
              <a
                href="https://appdelante.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit site
              </a>
            </p>
          </ProjectCard>
          <ProjectCard title="MyHabits" image={findImage("myhabits")}>
            <p>
              MyHabits is a beautiful Chrome extension to track your habits.
              Every time you open a new tab, you’ll see a dashboard with your
              progress.
            </p>
            <p>
              I{" "}
              <a
                href="https://www.producthunt.com/posts/myhabits"
                target="_blank"
                rel="noopener noreferrer"
              >
                launched MyHabits in ProductHunt
              </a>{" "}
              and the community loved it.
            </p>
            <p className="mb-0">
              <a
                href="https://myhabits.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit site
              </a>
            </p>
          </ProjectCard>
          <ProjectCard title="Clontagram" image={findImage("clontagram")}>
            <p>
              Clontagram is an Instagram clone. It's the final project of{" "}
              <a
                href="https://appdelante.com/cursos/descripcion/crea-un-clon-de-instagram-con-react-js"
                target="_blank"
                rel="noopener noreferrer"
              >
                my React.js course.{" "}
              </a>
              I teach students the fundamentals of React.js while we implement
              Instagram features.
            </p>
            <p className="mb-0">
              <a
                href="https://clontagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit site
              </a>
            </p>
          </ProjectCard>
          <ProjectCard title="Fliptomind" image={findImage("fliptomind")}>
            <p>
              FlipToMind is a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Spaced_repetition"
                target="_blank"
                rel="noopener noreferrer"
              >
                spaced repetition
              </a>{" "}
              web application. I started it in a live stream for Appdelante's
              Youtube channel, the idea was to create a product in less than 24
              hours. I couldn't complete the challenge but I eventually finished
              the product.
            </p>
            <p>
              It lets you create cards with content you'd like to memorize using
              markdown.
            </p>
            <p className="mb-0">
              <a
                href="https://fliptomind.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit site
              </a>
            </p>
          </ProjectCard>
        </div>
      </div>
    </Layout>
  )
}

function ProjectCard({ title, image, children }) {
  return (
    <div className="max-w-sm w-full max-w-full flex flex-col mb-8 shadow-lg">
      <div className="h-64 flex-none rounded-t-lg text-center overflow-hidden flex flex-col border border-b-0 border-gray-400">
        <Img
          fluid={image}
          className="flex-grow"
          alt="Ricardo Macario"
          imgStyle={{
            objectFit: "cover",
            objectPosition: "top center",
          }}
        />
      </div>
      <div className="border border-t-0 border-gray-400 bg-white rounded-b rounded-b-none px-4 py-8 flex flex-col justify-between leading-normal">
        <h2 className=" m-0 mb-4">{title}</h2>
        {children}
      </div>
    </div>
  )
}

export default Projects
